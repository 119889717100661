body {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    315deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(233, 255, 244, 1) 50%,
    rgba(255, 221, 221, 1) 100%
  );
}

.profileImage {
  background-image: url('./Images/flag.png');
  margin: 100; /* If you want no margin */
  padding: 0; /*if your want to padding */
}

.App {
  margin: 0 auto;
  max-width: 1440px;
}
